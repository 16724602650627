import { lazy, Suspense } from 'react';
import { Header } from '@parkhub/parkhub-ui';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ParkhubLogo from '../../assets/parkhub-live-logo-black.svg';
import { signOut, createFeatureAccess } from '../../utils';
import { LayoutContextProvider } from './LayoutContext';
const access = createFeatureAccess();

const LotsOverview = lazy(() => import('../LotsOverview/LotsOverview'));
const Overview = lazy(() => import('../Overview/Overview'));

const Layout = ({ authUser = {} }) => {
  const navigate = useNavigate();

  return (
    <LayoutContextProvider authUser={authUser}>
      <Header
        authUser={authUser}
        access={access}
        signOut={() => signOut()}
        logoSrc={ParkhubLogo}
        logoWidth={152}
        appMenuActive
        logoClick={() => navigate('/')}
      />
      <main id="layout">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Overview />
              </Suspense>
            }
          />
          <Route
            path="/lots"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <LotsOverview />
              </Suspense>
            }
          />
        </Routes>
      </main>
      <div id="dialog-portal" />
    </LayoutContextProvider>
  );
};

export default Layout;
