import { isNotEmpty } from '../../utils';
import cx from 'classnames';

const LotCards = ({ lots, setSelected }) => {
  function getWarningLevel(num) {
    if (num > 90) {

      return 'danger';
    }

    if (num > 70) {
      return 'warning';
    }

    return 'normal';
  }

  return (
    <div className="lot-cards-container">
      {isNotEmpty(lots) && (
        lots.map((lot, ind) => {
          const prepaid = lot.metrics?.transactionType.find((item) => item.key === 'Prepaid')?.parked || 0;
          const available = lot.totalSpots - lot.parked;
          const availableHeight = available * 100 / lot.totalSpots;
          const parkedHeight = lot.parked * 100 / lot.totalSpots;
          const prepaidHeight = lot.prepaid * 100 / lot.totalSpots;

          return (
            <div
              className={cx('lot-card-container', getWarningLevel(lot.utilization))}
              key={ind}
              onClick={() => setSelected({ ...lot })}
            >
              <div className="lot-card-title">
                <p>{lot.name}</p>
                <p>{lot.totalSpots} Total Spots</p>
              </div>
              <div className="graphic-container">
                <div className="barchart">
                  <div style={{ height: availableHeight > 0 ? `${availableHeight}%` : '0%' }} className="available-color" />
                  <div style={{ height: `${parkedHeight}%` }} className="total-color" />
                  <div style={{ height: `${prepaidHeight}%` }} className="prepaid-color" />
                </div>
                <div className="lot-card-metrics">
                  <div className={cx('lot-card-data-row', availableHeight < 0 && 'oversold')}>
                    <div className="data-legend">
                      <p className="legend-box available-color" />
                      <p>Available</p>
                    </div>
                    <p className="lot-metric">{available}</p>
                  </div>
                  <div className="lot-card-data-row">
                    <div className="data-legend">
                      <p className="legend-box total-color" />
                      <p>Total Parked</p>
                    </div>
                    <p className="lot-metric">{lot.parked}</p>
                  </div>
                  <div className="lot-card-data-row">
                    <div className="data-legend">
                      <p className="legend-box prepaid-color" />
                      <p>Prepaid</p>
                    </div>
                    <p className="lot-metric">{prepaid}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default LotCards;
