import { isNotEmpty, centsToCurrency, numberWithCommas } from '../../utils';
import {
  AppBar,
  AppBarTitle,
  AppBarAction,
  CloseSVGIcon,
  DropdownMenu,
  FontIcon,
  MenuConfigurationProvider,
  Radio,
  WarningFontIcon,
  useMenuVisibility
} from 'react-md';
import { Card, GraphBar } from '../';
import moment from 'moment';
import cx from 'classnames';

const LotsChart = ({
  currentDataType,
  currentSegmentBy,
  formatGraphBarValue,
  handleDataTypeChange,
  handleSegmentByChange,
  lots
}) => {
  function formatDate(date) {
    return moment(date).format('h:mm a');
  }

  function Header() {
    const { setVisible } = useMenuVisibility();

    return (
      <AppBar theme="clear">
        <AppBarTitle className="mobile-menu-title">View options:</AppBarTitle>
        <AppBarAction first last onClick={() => setVisible(false)}>
          <CloseSVGIcon />
        </AppBarAction>
      </AppBar>
    );
  }

  function makeUnique(array) {
    return [...new Set(array)];
  }

  return (
    <div className="segmentation-card-container">
      {lots.map((lot, index) => (
        <Card
          className={cx('segmentation-card', { 'warning-status': lot.totalSpots < lot.parked })}
          key={`${lot.id}-${lot.name}-${index}`}
        >
          <div className="segmentation-card-header segmentation-card-section">
            {lot.name &&
              <div className="segmentation-card-header-name">
                {lot.name}
              </div>
            }
            {lot.eventNames &&
              <div className="segmentation-card-header-content">
                {makeUnique(lot.eventNames).join(', ')}
              </div>
            }
          </div>
          <div className="segmentation-graph-container segmentation-card-section">
            {lot?.metrics?.[currentSegmentBy] && (
              <GraphBar
                data={lot.metrics[currentSegmentBy]}
                formatter={formatGraphBarValue}
                valueKey={currentDataType}
                valueLabel="key"
                indexKey={`${currentSegmentBy}.${currentDataType}`}
                defaultSubsegmentLabel="Spot Type Unavailable"
                defaultLabel="No Pricing Available"
              >
                <MenuConfigurationProvider
                  sheetHeader={<Header />}
                  sheetFooter={<div className="mobile-sheet-footer" />}
                  renderAsSheet
                  label="renderAsSheet"
                  sheetPosition="top"
                  sheetVerticalSize="recommended"
                >
                  <DropdownMenu
                    id="mobile-action-sheet"
                    icon={<FontIcon>more_vert</FontIcon>}
                  >
                    <form
                      name="segmentation-form-dropdown"
                      className="segmentation-form-dropdown"
                    >
                      <div className="segmentation-dropdown-controls">
                        <span>Segmented by</span>
                        <Radio
                          className={cx(currentSegmentBy === 'transactionType' && 'dropdown-radio-checked')}
                          id="transaction-type"
                          name="segment-radios"
                          label="Transaction type"
                          value="transactionType"
                          onChange={handleSegmentByChange}
                          checked={currentSegmentBy === 'transactionType'}
                        />
                        <Radio
                          className={cx(currentSegmentBy === 'pricing' && 'dropdown-radio-checked')}
                          id="pricing"
                          name="segment-radios"
                          label="Pricing"
                          value="pricing"
                          onChange={handleSegmentByChange}
                          checked={currentSegmentBy === 'pricing'}
                        />
                      </div>
                      <div className="segmentation-dropdown-controls">
                        <span>Data type</span>
                        <Radio
                          className={cx(currentDataType === 'parked' && 'dropdown-radio-checked')}
                          id="cars-parked"
                          name="data-radios"
                          label="Cars parked"
                          value="parked"
                          onChange={handleDataTypeChange}
                          checked={currentDataType === 'parked'}
                        />
                        <Radio
                          className={cx(currentDataType === 'revenue' && 'dropdown-radio-checked')}
                          id="revenue"
                          name="data-radios"
                          label="Revenue"
                          value="revenue"
                          onChange={handleDataTypeChange}
                          checked={currentDataType === 'revenue'}
                        />
                      </div>
                    </form>
                  </DropdownMenu>
                </MenuConfigurationProvider>
              </GraphBar>
            )}
          </div>
          <div className="segmentation-details segmentation-card-section">
            <dl>
              <>
                <dt className="location-details">
                  Location
                </dt>
                <dd className="location-details">
                  {lot.landmarkNames}
                </dd>
              </>
              {isNotEmpty(lot.eventNames)}
              <>
                <dt className="event-details">
                  Events
                </dt>
                <dd className="event-details">
                  {makeUnique(lot.eventNames).join(', ')}
                </dd>
              </>
              {currentDataType === 'parked' &&
                <>
                  <dt className="utilization-details">
                    Utilized Inventory / Total Inventory
                    {lot.totalSpots < lot.parked &&
                      <WarningFontIcon
                        title={`${numberWithCommas(lot.parked - lot.totalSpots)} oversold`}
                        className="warning-icon"
                      />
                    }
                  </dt>
                  <dd className="warning-value utilization-details">
                    {lot.parked || '-'} / {lot.totalSpots || '-'}
                  </dd>
                </>
              }
              {currentDataType === 'revenue' &&
                <>
                  <dt>
                    Total Revenue
                  </dt>
                  <dd>
                    {centsToCurrency(lot.totalRevenue)}
                  </dd>
                </>
              }
              {currentDataType === 'revenue' &&
                <>
                  <dt>
                    Onsite Revenue
                  </dt>
                  <dd>
                    {centsToCurrency(lot.onSiteRevenue)}
                  </dd>
                </>
              }
              {isNotEmpty(lot.cashiers) &&
                <>
                  <dt>
                    Cashiers
                  </dt>
                  <dd>
                    {lot.cashiers.length}
                  </dd>
                </>
              }
              <>
                <dt>
                  Parking times
                </dt>
                <dd>
                  {lot.parkingFrom ? formatDate(lot.parkingFrom) : '?'} - {lot.parkingTo ? formatDate(lot.parkingTo) : '?'}
                </dd>
              </>
              <>
                <dt className="event-time-details">
                  Event times
                </dt>
                <dd className="event-time-details">
                  {lot.eventsFrom ? formatDate(lot.eventsFrom) : '?'} - {lot.eventsTo ? formatDate(lot.eventsTo) : '?'}
                </dd>
              </>
            </dl>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default LotsChart;
