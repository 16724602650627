import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GraphBarCell, GraphBarDetails } from '../';
import './GraphBar.scss';

const GraphBar = ({
  children,
  data = [],
  valueKey = '',
  formatter = ((value) => value),
  valueLabel = '',
  indexKey = '',
  defaultSubsegmentLabel,
  defaultLabel
}) => {
  // Get the total value to calculate the width of cell
  const totalValue = useMemo(() => {
    let initialValue = 0;

    return data.reduce((accumulator, current) => {
      return accumulator + current[valueKey];
    }, initialValue);
  }, [data, valueKey]);

  function calculateWidth(width, total) {
    return (((width >= 0 ? width : 0) / total) * 100);
  }

  function createCells() {
    let cells = [];

    data.forEach(element => {
      cells.push({
        key: element.key,
        width: calculateWidth(element[valueKey], totalValue),
        cells: [{
          key: element.key,
          value: element[valueKey],
          title: `${element[valueLabel]} - ${formatter(element[valueKey])}`
        }]
      });
    });

    return cells;
  }

  const CELLS = createCells();

  return (
    <>
      <div className="graph-bar">
        <div className="graph-bar-chart">
          {CELLS.map((item, index) => {
            return item.width > 0 ? (
              <div
                key={`${item.key}-${indexKey}-${index}`}
                className="graph-bar-chart-cell-container"
                style={{
                  width: `${item.width}%`
                }}
              >
                <GraphBarCell
                  item={item.cells[0]}
                  index={index}
                  title={item.cells[0].title}
                />
              </div>
            ) : null;
          })}
        </div>
        <div className="menu-container">
          {children}
        </div>
      </div>
      <GraphBarDetails
        data={data}
        valueLeftKey={valueKey}
        valueLabel={valueLabel}
        formatter={formatter}
        defaultSubsegmentLabel={defaultSubsegmentLabel}
        defaultLabel={defaultLabel}
      />
    </>
  );
};

GraphBar.propTypes = {
  data: PropTypes.array,
  defaultLabel: PropTypes.string,
  defaultSubsegmentLabel: PropTypes.string,
  formatter: PropTypes.func,
  indexKey: PropTypes.string,
  valueKey: PropTypes.string,
  valueLabel: PropTypes.string
};

export default GraphBar;
