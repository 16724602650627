import { gql } from '@apollo/client';

const FRAGMENTS = gql`
  fragment lotSegment on LotSegment {
    subsegment {
      key
      parked
      revenue
    }
    key
    parked
    revenue
  }

  fragment LotOverviewsFragment on LotOverview {
    cashRevenue
    cashiers
    eventNames
    eventsFrom
    eventsTo
    id
    landmarkNames
    lat
    lon
    name
    parked
    parkingFrom
    parkingTo
    successfulTransactions
    totalSpots
    utilization
    value
    resolveTime
    updateTime
    totalRevenue
    onSiteRevenue
    successfulTransactions
    metrics{
      transactionType{
        ...lotSegment
      }
      pricing{
        ...lotSegment
      }
    }
  }
`;

export const METRICS_LOT_OVERVIEWS_QUERY = gql`
  ${FRAGMENTS}

  query MetricsLotOverviewsQuery(
    $keyword: String
    $limit: Int
    $offset: Int
    $order: SortOrder
    $bypassCache: Boolean
    $events: [ID]
    $lots: [ID]
    $landmarks: [ID]
    ) {
    metrics(
      keyword: $keyword
      limit: $limit
      offset: $offset
      order: $order
      bypassCache: $bypassCache
      events: $events
      lots: $lots
      landmarks: $landmarks
    ) {
      lotOverviews {
        ...LotOverviewsFragment
      }
    }
  }
`;

export const METRICS_LOT_OVERVIEWS_SUBSCRIPTION = gql`
  ${FRAGMENTS}

  subscription MetricsLotOverviewsSubscription(
    $landmarks: [ID]
    $events: [ID]
    $lots: [ID]
    ) {
    metrics(
      landmarks: $landmarks
      events: $events
      lots: $lots
    ) {
      lotOverviews {
        ...LotOverviewsFragment
      }
    }
  }
`;
