import axios from 'axios';
import { useState } from 'react';
import { Button } from 'react-md';
import { useCashiersContext, useLayoutContext } from '../../hooks';

const ReviewSubmitButton = () => {
  const {
    activeForm = '',
    currentFormData: {
      bagId = '',
      cashCollected = 0,
      cashToTake = 0,
      comments = '',
      initialBank = 0,
      newTotal = 0,
      transactionExternalId = ''
    } = {},
    selectedCashier = {},
    selectedEvent: { eventId = '' } = {},
    setActiveForm,
    setCurrentDialog,
    setCurrentFormData
  } = useCashiersContext();
  const [errorCode, setErrorCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formState, setFormState] = useState('idle');

  const { authUser = {} } = useLayoutContext();

  const rootUrl = process.env.REACT_APP_BASE_API_URL;
  const connector = rootUrl.charAt(rootUrl.length - 1) === '/' ? '' : '/';
  const apiUrl = `${rootUrl}${connector}api/v1/transactions/`;

  const submissionUrl = {
    'cash count': `${apiUrl}cash-counts/${transactionExternalId}`,
    'cash out': `${apiUrl}cash-outs`,
    recount: `${apiUrl}cash-counts/${transactionExternalId}`
  };

  const submissionBody = {
    'cash count': {
      amount: cashCollected || 0,
      bagId: bagId || '',
      counterExternalId: authUser.pk || ''
    },
    recount: {
      amount: newTotal || 0,
      bagId: bagId || '',
      counterExternalId: authUser.pk || '',
      comments: comments || ''
    },
    'cash out': {
      amount: cashToTake - initialBank || 0,
      bagId: bagId || '',
      comments: comments || '',
      didPrintAudit: false,
      eventId: eventId || '',
      force: false,
      cashierLegacyId: selectedCashier.id || '',
      initialBankAmount: initialBank,
      remainder: 0,
      supervisorExternalId: authUser.pk || ''
    }
  };

  const submission = {
    url: submissionUrl[activeForm],
    body: submissionBody[activeForm]
  };

  const transport = axios.create({
    withCredentials: true
  });

  const submitData = async () => {
    setErrorMessage('');
    setFormState('sending');

    const resp = await transport
      .post(submission.url, submission.body)
      .then(() => {
        setFormState('success');
        setCurrentDialog('success');
      })
      .catch(err => {
        const defaultMessage = 'There was an error submitting your transaction. Please try again.';
        const { response = {} } = err;
        const { data = {}, status = 0, statusText = '' } = response;
        const errorResponse = data?.message || defaultMessage;
        setErrorMessage(`${errorResponse}`);
        setErrorCode(`${status} | ${statusText}`);
        setFormState('error');
        console.error(err);
      });

    return resp;
  };

  const buttonText = {
    default: `Complete ${activeForm}`,
    error: 'Resubmit',
    idle: `Complete ${activeForm}`,
    sending: `Submitting ${activeForm}`,
    success: `${activeForm} submitted`
  };

  const cancelSubmission = () => {
    setActiveForm('');
    setCurrentDialog('cash management');
    setCurrentFormData(null);
  };

  return (
    <>
      {formState === 'error' && (
        <>
          <p className="modal-form-review-error">
            <b>Error: {errorCode}</b>
          </p>
          <p className="modal-form-review-error">{errorMessage}</p>
        </>
      )}
      <div className="modal-form-review-buttons">
        {formState === 'error' && (
          <Button
            theme="secondary"
            themeType="contained"
            className="button-gray"
            onClick={cancelSubmission}
          >
            Exit
          </Button>
        )}
        <Button
          disabled={['idle', 'sending', 'success'].includes('formState')}
          theme="primary"
          themeType="contained"
          className="button-blue"
          onClick={submitData}
        >
          {buttonText[formState] || buttonText['default']}
        </Button>
      </div>
    </>
  );
};

export default ReviewSubmitButton;
