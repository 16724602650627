import React, { Fragment, useState, useEffect } from 'react';
import { FontIcon, TextField, Button, Switch, LinearProgress } from 'react-md';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { RefundIcon } from '../../../assets/react-icons';
import { isNotEmpty, getAuthUser } from '../../../utils';
import { useDebounce } from '../../../hooks';
import { CREATE_REFUND } from '../../../graphql/refunds.mutation';
import './Refunds.scss';

const Refunds = ({
  transaction,
  refunds,
  refetchTransactions,
  transactionError
}) => {
  const [refundAction, setRefundAction] = useState(false);
  const [refundReason, setRefundReason] = useState('');
  const [refundComment, setRefundComment] = useState('');
  const [formChanged, setFormChanged] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const debouncedRefetch = useDebounce(() => handleRefetch(), 1500);

  const [createRefund, { loading: createRefundLoading, error: createRefundError }] = useMutation(CREATE_REFUND, {
    onError: (err) => console.error('error', err),
    onCompleted: () => {
      setTransactionLoading(true);
      debouncedRefetch();
    }
  });

  useEffect(() => {
    if (isNotEmpty(refunds)) {
      const { action, reason, comments } = refunds[0];
      setRefundAction(action === 'REFUNDED' ? true : false);
      setRefundReason(reason);
      setRefundComment(comments);
    }
  }, [refunds]);

  async function handleRefetch() {
    await refetchTransactions();
    setTransactionLoading(false);
  }

  function handleRefundActionChange(event) {
    const value = event.target.checked;
    setRefundAction(value);

    if (!value) {
      setRefundComment(refunds[0]?.comments || '');
      setRefundReason(refunds[0]?.reason || '');
    }

    setFormChanged(true);
  }

  function handleRefundReasonChange(event) {
    const reason = event.target.value;

    setRefundReason(reason);

    if (reason !== 'OTHER') {
      setRefundComment('');
    }

    setFormChanged(true);
  }

  function handleRefundCommentChange(event) {
    setRefundComment(event.target.value);
    setFormChanged(true);
  }

  function isSaveDisabled() {
    const reasonSelected = refundReason !== '';
    const isRefunded = refundAction;
    const isPrevRefunded = refunds[0]?.action === 'REFUNDED';

    if (createRefundLoading || transactionLoading) {
      return true;
    }

    if (isNotEmpty(refunds)) {
      if (isRefunded && isPrevRefunded) {
        return true;
      }

      if (!isRefunded && !isPrevRefunded) {
        return true;
      }
    } else {
      return isRefunded && reasonSelected ? false : true;
    }
  }

  function handleDisableInputs() {
    if (isNotEmpty(refunds)) {
      return true;
    }

    return !refundAction;
  }

  function handleSave() {
    const variables = {
      refund: {
        performedAt: moment().toISOString(),
        transactionId: transaction.transactionId,
        reason: refundAction ? refundReason : refunds[0].reason,
        action: refundAction ? 'REFUNDED' : 'NOT_REFUNDED',
        comments: refundAction ? refundComment : refunds[0].comments,
        employeeId: getAuthUser().pk
      }
    };

    createRefund({
      variables
    });

    setFormChanged(false);
  }

  function handleReset() {
    if (isNotEmpty(refunds)) {
      setRefundAction(refunds[0].action === 'REFUNDED' ? true : false);
      setRefundReason(refunds[0].reason);
      setRefundComment(refunds[0].comments);

    } else {
      setRefundAction(false);
      setRefundReason('');
      setRefundComment('');
    }

    setFormChanged(false);
  }

  function parseError(error) {
    if (error.match(/status code 400/)) {
      return `Can't set non-refunded as first refund action`;
    } else {
      return 'There was a problem creating the refund.';
    }
  }

  function parseAction(action) {
    const actions = {
      NOT_REFUNDED: 'unmarked as refunded',
      REFUNDED: 'marked as refunded'
    };

    return actions[action];
  }

  return (
    <div className="refunds-container">
      <hr className="divider" />
      <div className="refund-actions">
        <dl>
          <dt>
            <RefundIcon color="#666" width={16} /> Marked as refunded
          </dt>
          <dd>
            <Switch
              id="refund-action"
              name="refund-action"
              onChange={handleRefundActionChange}
              aria-label="refund-action"
              checked={refundAction}
            />
          </dd>
          <dt>
            <FontIcon>help_outline</FontIcon> Refund Reason
          </dt>
          <dd>
            <select
              id="refund-reason"
              name="refund-reason"
              onChange={handleRefundReasonChange}
              className="select-input"
              value={refundReason}
              disabled={handleDisableInputs()}
            >
              <option value="">Select Reason</option>
              <option value="WRONG_LOT">Wrong lot</option>
              <option value="CHARGED_TOO_MANY_TIMES">Charged too many times</option>
              <option value="CASHIER_ERROR">Cashier Error</option>
              <option value="OTHER">Other</option>
            </select>
          </dd>
          {refundReason === 'OTHER' &&
            <>
              <dt>
                <FontIcon>help_outline</FontIcon> "Other" description
              </dt>
              <dd>
                <TextField
                  id="refund-other-input"
                  name="refund-other-input"
                  onChange={handleRefundCommentChange}
                  value={refundComment}
                  placeholder="Type reason here..."
                  maxLength={100}
                  className="refund-input"
                  disabled={handleDisableInputs()}
                />
              </dd>
            </>
          }
        </dl>
      </div>
      <div className="save-reason-action-container">
        <Button
          id="reset-button"
          theme="secondary"
          themeType="contained"
          onClick={handleReset}
          disabled={!formChanged}
        >
          Reset
        </Button>
        <Button
          id="save-button"
          theme="primary"
          themeType="contained"
          onClick={handleSave}
          disabled={isSaveDisabled()}
        >
          Save Refund
        </Button>
      </div>
      <div className="error-container">
        {transactionError &&
          <span className="error-message">
            Oops! Something went wrong!
          </span>
        }
        {createRefundError &&
          <span className="error-message">
            {parseError(createRefundError?.message)}
          </span>
        }
      </div>
      {isNotEmpty(refunds) &&
        <div className="refund-history">
          <div className="linear-progress-container">
            {(transactionLoading || createRefundLoading) &&
              <LinearProgress id="refunds-loading-progress" />
            }
          </div>
          <dl>
            {refunds.map((item, index) => (
              <Fragment key={`${item.performedAt}+${index}`}>
                <dt>
                  <strong>{item.employeeName}</strong> {parseAction(item.action)}
                </dt>
                <dd>{moment(item.performedAt).format('MM/DD/YYYY h:mm a')}</dd>
              </Fragment>
            ))}
          </dl>
        </div>
      }
    </div>
  );
};

export default Refunds;
