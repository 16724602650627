import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FILTERS_QUERY } from '../../graphql/filters.query';
import { METRICS_LOT_OVERVIEWS_QUERY, METRICS_LOT_OVERVIEWS_SUBSCRIPTION } from '../../graphql/lotOverviews.operation';
import { useAddMessage, FontIcon, LinearProgress, Button } from 'react-md';
import { useLayoutContext, useDebounce } from '../../hooks';
import { isNotEmpty, Logger, FUSE_OPTIONS } from '../../utils';
import { LotCards, SearchInput, MapBox } from '../../components/';
import Fuse from 'fuse.js';
import cx from 'classnames';
import './LotsOverview.scss';

const LOT_OVERVIEWS_DISABLED = process.env.REACT_APP_LOT_OVERVIEWS_ENABLED === 'false';

const LotsOverview = () => {
  const addMessage = useAddMessage();
  const { isLoading, setIsLoading } = useLayoutContext();
  const [gridView, setGridView] = useState(false);
  const [searchTerm, setSearchTerm] = useState(true);
  const [filters, setFilters] = useState({ events: [], lots: [], landmarks: [] });
  const [pageLoaded, setPageLoaded] = useState(false);
  const applyChange = useDebounce((nextValue) => setSearchTerm(nextValue), 500);
  const [sortDesc, setSortDesc] = useState(true);
  const [selectedLot, setSelectedLot] = useState();

  const {
    loading: filtersLoading
  } = useQuery(FILTERS_QUERY, {
    onError: (error) => {
      Logger('filtersError: ', error.message);
      handleError('Error getting filters');
    },
    onCompleted: (data) => {
      setFilters({
        events: formatFilterData(data.filters.events),
        lots: formatFilterData(data.filters.lots),
        landmarks: formatFilterData(data.filters.landmarks)
      });
      setPageLoaded(true);
    }
  });

  const {
    data: metricsLotOverviewsData,
    loading: metricsLotOverviewsLoading,
    subscribeToMore: subscribeToMoreMetricsLotOverviews,
    refetch: refetchMetricsLotOverviews
  } = useQuery(METRICS_LOT_OVERVIEWS_QUERY, {
    variables: filters,
    skip: LOT_OVERVIEWS_DISABLED || (!pageLoaded && !isNotEmpty(filters.events)),
    errorPolicy: 'all',
    onCompleted: (data) => {
      Logger('metricsCashiersData', data?.metrics?.lotOverviews);
    },
    onError: (error) => {
      Logger('metricsLotOverviewsError', error.message);
      handleError('Error getting lots');
    }
  });

  useEffect(() => {
    let unsubscribe;

    if (!LOT_OVERVIEWS_DISABLED && isNotEmpty(filters.events) && metricsLotOverviewsLoading === false) {
      unsubscribe = subscribeToMoreMetricsLotOverviews({
        document: METRICS_LOT_OVERVIEWS_SUBSCRIPTION,
        variables: filters,
        updateQuery: (prev, { subscriptionData }) => {
          Logger('METRICS_LOT_OVERVIEWS_SUBSCRIPTION', subscriptionData);

          if (!subscriptionData?.data?.metrics?.lotOverviews) {
            return prev;
          }

          return Object.assign({}, prev, {
            metrics: {
              lotOverviews: subscriptionData?.data?.metrics?.lotOverviews
            }
          });
        }
      });
    }

    if (unsubscribe) {
      return () => unsubscribe();
    }
  }, [subscribeToMoreMetricsLotOverviews, filters, metricsLotOverviewsLoading]);

  useEffect(() => {
    setIsLoading([
      filtersLoading,
      metricsLotOverviewsLoading
    ].includes(true));
  }, [
    setIsLoading,
    filtersLoading,
    metricsLotOverviewsLoading
  ]);

  useEffect(() => {
    if (isNotEmpty(filters) && pageLoaded) {
      !LOT_OVERVIEWS_DISABLED && refetchMetricsLotOverviews(filters);
    }
  }, [
    filters,
    refetchMetricsLotOverviews,
    pageLoaded
  ]);

  function handleError(message) {
    addMessage({
      children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; {message}</>,
      action: 'dismiss'
    });
  }

  function formatFilterData(list) {
    return list.map(item => item.selected ? item.id : null)
      .filter(item => item !== null);
  }

  function handleClick() {
    setGridView(!gridView);
    setSearchTerm('');
  }

  function handleSearch(e) {
    applyChange(e.target.value);
  }

  function createLotsList() {
    const options = { ...FUSE_OPTIONS, keys: ['name'] };
    const fuse = new Fuse(metricsLotOverviewsData?.metrics?.lotOverviews, options);
    const results = searchTerm.length > 0 ? fuse.search(searchTerm).map(result => result.item) : metricsLotOverviewsData?.metrics?.lotOverviews;

    return results;
  }

  const lots = searchTerm && gridView ? createLotsList() : metricsLotOverviewsData?.metrics?.lotOverviews;

  function sortList(data, sortProperty) {
    const sorted = [...data].sort((a, b) => a[sortProperty] - b[sortProperty]);

    return sortDesc ? sorted.reverse() : sorted;
  }

  return (
    <div id="lots-overview">
      <div className="linear-progress-container">
        {isLoading &&
          <LinearProgress id="progress-indicator" />
        }
      </div>
      <div className={cx('lots-view-container', gridView ? 'grid-view' : 'map-view')}>
        <div className="top-action-bar">
          {isNotEmpty(lots) && gridView ? (
            <SearchInput placeholder="Search by keyword" onChange={handleSearch} />
          ) : (
            <div onClick={() => setSortDesc(!sortDesc)} className="sort-container">
              <p>Sort</p>
              <FontIcon className="expand">expand_more</FontIcon>
            </div>
          )}
          <div className="toggle-grid">
            <FontIcon>{gridView ? 'location_on' : 'list'}</FontIcon>
            <Button onClick={handleClick}>{gridView ? 'Map View' : 'Grid View'}</Button>
          </div>
        </div>
        {isNotEmpty(lots) && (
          <LotCards lots={sortList(lots, 'utilization')} setSelected={setSelectedLot} />
        )}
        {isNotEmpty(lots) && !gridView && (
          <MapBox lots={lots} selectedLot={selectedLot} />
        )}
      </div>
    </div>
  );
};

export default LotsOverview;
